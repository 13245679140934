
import { defineComponent } from 'vue'
import { BillDetailDto, BillDto } from '@/api/bill'
import { dateFormat, downLoadFile } from '@/utils/util'
import {
    getDerivationRoster,
    disposableTransposingArr,
    longInsureRecord,
    longQueryPlans
} from '@/api/long-insure'
import { updateLongEnsureWorker } from '@/api/export'
import { queryPlans } from '@/api/insure-deduction'
import store from '@/store/index'
import axios from 'axios'
export default defineComponent({
    name: 'LongEnsureWorker',
    components: {},
    data () {
        return {
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            isDeletePopup: false,
            idCardNo: '',
            personnelInfo: [] as BillDetailDto[],
            renewalManufactory: [] as disposableTransposingArr[],
            conmpanyPlanId: 0,
            isFactory: false,
            selectTime: dateFormat('YYYY-mm-dd', new Date()), // 筛选选择的时间
            plansList: [],
            comboCode: ''
        }
    },
    created () {
        this.getQueryInsureRecord()
        this.getQueryPlans()
    },
    mounted () {
        // 获取完数据调用
        this.$nextTick(() => {
            // this.$refs.dataTable.toggleAllSelection()
        })
        this.$Bus.on('close2', (e) => {
            console.log(e, 'eeee')
            if (e) {
                this.isFactory = false
            }
        })
    },
    methods: {
        async getDerivationRoster () {
            const result = await updateLongEnsureWorker({
                searchText: this.searchText,
                queryDate: dateFormat('YYYY-mm-dd', new Date(this.selectTime)),
                companyPlanId: this.comboCode
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        },
        handleSizeChange () {
            this.pageNum = 1
			this.getQueryInsureRecord()
        },
        rowKeyInit (row: any) {
            return row.id
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getQueryInsureRecord()
        },
        onReset () {
            this.searchText = ''
            this.comboCode = ''
            this.search()
        },
        // 获取长期保险在保人员名单
        async getQueryInsureRecord () {
            const result = await longInsureRecord({
                searchText: this.searchText,
                queryDate: dateFormat('YYYY-mm-dd', new Date(this.selectTime)),
                companyPlanId: this.comboCode,
                pageSize: this.pageSize,
                pageNum: this.pageNum
            })
			// console.log(result, 'result')
            this.tableData = result.rows
			this.total = result.total
        },
        // 获取长期保方案列表信息
        async getQueryPlans () {
            const result = await longQueryPlans()
            console.log(result, 'result')
            this.plansList = result.map((item:any) => {
                return {
                    value: item.id,
                    label: item.insurCompanyPlanName
                }
            })
        }
    }
})
